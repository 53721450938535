import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandlerService } from './../shared/global-error-handler.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  //#region 'Angular Life Cycle'
  constructor(public router: Router, public errorHandler: GlobalErrorHandlerService) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Interceptor'
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.router.navigate(['/not-authorized']);
            return of(null);
          case 403:
            this.router.navigate(['/not-authorized']);
            return of(null);
        }

        return throwError(() => new Error(error.message));
      })
    );
  }
  //#endregion 'Interceptor'
}
