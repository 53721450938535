import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html'
})
export class GenericErrorComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'
}
