import { Component, OnInit } from '@angular/core';
import { AuthService } from '@serv/shared/auth.service';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./../auth.styles.scss']
})
export class LogoutComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor(private authSvc: AuthService) {}

  ngOnInit(): void {
    if (this.authSvc.isAuthenticated) {
      this.authSvc.logout();
    }
  }
  //#endregion 'Angular Life Cycle'
}
