import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  ClientAuthError,
  InteractionRequiredAuthError,
  RedirectRequest
} from '@azure/msal-browser';
import { Observable, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof ClientAuthError) {
          return this.handleClientAuthError(request, next);
        }

        if (err instanceof InteractionRequiredAuthError) {
          return this.handleInteractionRequiredAuthError(request, next);
        }

        throw err;
      })
    );
  }

  private handleClientAuthError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.msalService.instance.loginRedirect();
    return this.handleRedirectAndRetry(request, next);
  }

  private handleInteractionRequiredAuthError(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const redirectRequest: RedirectRequest = { scopes: ['openid', 'profile', 'user.read'] };
    this.msalService.instance.acquireTokenRedirect(redirectRequest);
    return this.handleRedirectAndRetry(request, next);
  }

  private handleRedirectAndRetry(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.msalService.instance.handleRedirectPromise()).pipe(
      switchMap((result: AuthenticationResult | null) => {
        if (result && result.account) {
          return next.handle(request);
        } else {
          this.msalService.instance.logout();
          throw new Error('Authentication failed');
        }
      })
    );
  }
}
