import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '@serv/shared/loading-indicator.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  //#region 'Variables'
  public isLoading$: Observable<boolean>;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private loadingIndicatorService: LoadingIndicatorService) {}

  ngOnInit(): void {
    this.isLoading$ = this.loadingIndicatorService.status$;
  }
  //#endregion 'Angular Life Cycle'
}
