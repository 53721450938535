import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { TabMenuModule } from 'primeng/tabmenu';
import { LoadingIndicatorModule } from '../../shared/loading-indicator/loading-indicator.module';
import { MainComponent } from './main.component';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    LoadingIndicatorModule,
    TabMenuModule,
    SidebarModule,
    MenuModule,
    ButtonModule,
    ComponentsModule
  ],
  exports: []
})
export class MainModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MainModule
  ) {
    if (parentModule) {
      throw new Error('MainModule is already loaded. Import only in AppModule');
    }
  }
}
