import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  //#region 'Variables'
  private statusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status$: Observable<boolean> = this.statusSubject$.asObservable();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public show(): void {
    this.statusSubject$.next(true);
  }

  public hide(): void {
    this.statusSubject$.next(false);
  }
  //#endregion 'General Methods'
}
