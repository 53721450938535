import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = (): void => {};
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(module => {
    if (!environment.production) {
      const applicationRef = module.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);
    }
  })
  .catch(err => console.error(err));
