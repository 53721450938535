import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UserApiService } from '@serv/api';
import { AuthService } from '@serv/shared/auth.service';
import { LocalStorageService } from '@serv/shared/local-storage.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-disclaimer-notice',
  templateUrl: './disclaimer-notice.component.html',
  styleUrls: ['./disclaimer-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerNoticeComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  private _sub$: Subscription = new Subscription();
  public msg: string = '';
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _apiUser: UserApiService,
    private _srvAuth: AuthService,
    public ddRef: DynamicDialogRef,
    private readonly _ls: LocalStorageService
  ) {}

  ngOnDestroy(): void {
    if (this._sub$) {
      this._sub$.unsubscribe();
    }
  }

  ngOnInit(): void {
    const DTO = this._ls.getItem('statusMessages');
    this.msg = DTO.find((x: any) => x.MessageType === 2).Message;
  }
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  public onAcceptDisclaimer(): void {
    const hasAcceptedDisclaimer$ = this._apiUser
      .acceptDisclaimer(this._srvAuth.currentUser.ID)
      .pipe(
        map((data: any) => data?.DisclaimerAccepted),
        tap((isAccepted) => this.ddRef.close(isAccepted)),
        catchError(() => {
          this.ddRef.close(false);
          return EMPTY;
        })
      )
      .subscribe();
    this._sub$.add(hasAcceptedDisclaimer$);
  }
  //#endregion 'General Methods'
}
