import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusMessage } from '@models/StatusMessage.model';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../shared';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class StatusMessageApiService extends BaseCrudService<StatusMessage, number> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient, private readonly _ls: LocalStorageService) {
    super(_http, 'StatusMessage');
  }
  //#endregion

  //#region 'Get'
  //#endregion

  //#region 'Create'
  public CreateStatusMessage(dto: Partial<StatusMessage>): Observable<any> {
    return this._http.post<any>(
      `${this._apiEndpoint}?Message=${dto.Message}&MessageType=${dto.MessageType}&Expires=${dto.Expires}&Link=${dto.Link}`,
      dto
    );
  }
  //#endregion

  //#region 'Update'
  public UpdateStatusMessage(dto: Partial<StatusMessage>): Observable<any> {
    return this._http.post<any>(
      `${this._apiEndpoint}/UpdateStatusMessage?ID=${dto.ID}&Message=${dto.Message}&MessageType=${dto.MessageType}&Expires=${dto.Expires}&Link=${dto.Link}`,
      dto
    );
  }
  //#endregion

  //#region 'Delete'
  public DeleteStatusMessage(Id: number): any {
    return this._http.delete(`${this._apiEndpoint}/DeleteStatusMessage?id=${Id}`, {
      responseType: 'text'
    });
  }
  //#endregion

  //#region 'Other'
  public saveMsgsToStorages(): void {
    this.getAll().subscribe((dto) => {
      this._ls.removeItem('statusMessages');
      this._ls.setItem('statusMessages', dto);
    });
  }
  //#endregion
}
