<!-- Content -->
<div class="layout__wrapper">
  <!-- Header -->
  <app-header></app-header>
  <!-- Header -->

  <!-- NavBar -->
  <app-navbar data-html2canvas-ignore="true"></app-navbar>
  <!-- NavBar -->

  <!-- Heat Exchanger Content -->
  <main>
    <app-loading-indicator></app-loading-indicator>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
  <!-- Heat Exchanger Content -->

  <!-- Footer -->
  <app-footer data-html2canvas-ignore="true"></app-footer>
  <!-- Footer -->
</div>
<!-- Content -->
