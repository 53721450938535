import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISystemAdmin } from '@models/index';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class SystemAdminService extends BaseCrudService<ISystemAdmin, number> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'system-admin');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  //#endregion 'Get'

  //#region 'Create'
  //#endregion 'Create'

  //#region 'Update'
  //#endregion 'Update'

  //#region 'Delete'
  //#endregion 'Delete'

  //#region 'Other'
  //#endregion 'Other'
}
