import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';

export interface ICrudOperations<T, IdType> {
  getAll(): Observable<T[]>;
  getById(id: IdType): Observable<T>;
  create(dto: T): Observable<T>;
  update(id: IdType, dto: T): Observable<T>;
  deleteById(id: IdType): Observable<any>;
}

export class BaseCrudService<Type, IdType> implements ICrudOperations<Type, IdType> {
  //#region 'Variables'
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
  };
  protected readonly _apiEndpoint: string;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient, protected _url: string) {
    this._apiEndpoint = `${environment.apiEndpoint}/${_url}`;
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getAll(): Observable<Type[]> {
    return this._http.get<Type[]>(`${this._apiEndpoint}`, this.httpOptions);
  }

  public getById(id: IdType): Observable<Type> {
    return this._http.get<Type>(`${this._apiEndpoint}/${id}`, this.httpOptions);
  }
  //#endregion 'Get'

  //#region 'Create'
  public create(dto: Type): Observable<Type> {
    return this._http.post<Type>(`${this._apiEndpoint}`, dto, this.httpOptions);
  }
  //#endregion 'Create'

  //#region 'Update'
  public update(id: IdType, dto: Partial<Type>): Observable<Type> {
    return this._http.put<Type>(`${this._apiEndpoint}/${id}`, dto, this.httpOptions);
  }
  //#endregion 'Update'

  //#region 'Delete'
  public deleteById(id: IdType): Observable<any> {
    return this._http.delete(`${this._apiEndpoint}/${id}`, {
      responseType: 'text'
    });
  }
  //#endregion 'Delete'

  //#region 'Other'
  /* tslint:disable:typedef */
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      //this.logger.logError(error);

      // TODO: better job of transforming error for user consumption
      //this.logger.logError(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /* tslint:enable:typedef */
  //#endregion 'Other'
}
