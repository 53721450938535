import { Injectable, NgZone } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

//#region 'Enums'
// Valid Severity level for the message services are "success", "info", "warn" and "error".
export enum MessageSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}
//#endregion 'Enums'

//#region 'Global Variables'
const DEFAULT_MESSAGE_CONFIG: Message = {
  life: 3000
};
//#endregion 'Global Variables'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  //#region 'Angular Life Cycle'
  constructor(private _srvMsg: MessageService, private readonly zone: NgZone) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Actions'
  public showSuccess(message: string = '', config: Message = {}): void {
    this.show(message, MessageSeverity.SUCCESS, {
      ...DEFAULT_MESSAGE_CONFIG,
      ...config,
      summary: 'Success'
    });
  }

  public showInfo(message: string = '', config: Message = {}): void {
    this.show(message, MessageSeverity.INFO, {
      ...DEFAULT_MESSAGE_CONFIG,
      ...config,
      summary: 'Info'
    });
  }

  public showWarning(message: string = '', config: Message = {}): void {
    this.show(message, MessageSeverity.WARN, {
      ...DEFAULT_MESSAGE_CONFIG,
      ...config,
      summary: 'Warning'
    });
  }

  public showError(message: string = '', config: Message = {}): void {
    this.show(message, MessageSeverity.ERROR, {
      ...DEFAULT_MESSAGE_CONFIG,
      ...config,
      summary: 'Error'
    });
  }

  public clear(key?: string): void {
    this._srvMsg.clear(key);
  }
  //#endregion 'Actions'

  //#region 'Show'
  // Need to open toastr from Angular Zone if doing api calls
  public show(
    message: string = '',
    severity: MessageSeverity = MessageSeverity.INFO,
    config: Message = DEFAULT_MESSAGE_CONFIG
  ): void {
    this.zone.run(() =>
      this._srvMsg.add({
        ...config,
        severity,
        detail: message
      })
    );
  }
  //#endregion 'Show'
}
