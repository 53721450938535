import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBusinessUnit } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService extends BaseCrudService<IBusinessUnit, number> {
  //#region 'Variables'
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'business-unit');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getUserBusinessUnitsByBUId(buId: number): Observable<IBusinessUnit[]> {
    return this._http.get<IBusinessUnit[]>(`${this._apiEndpoint}/businessUnitUsers/${buId}`);
  }
  public listBusinessUnits(): Observable<IBusinessUnit[]> {
    return this._http.get<IBusinessUnit[]>(`${this._apiEndpoint}/list`);
  }
  //#endregion 'Get'

  //#region 'Create'
  //#endregion 'Create'

  //#region 'Update'
  //#endregion 'Update'

  //#region 'Delete'
  //#endregion 'Delete'
}
