import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFacility } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService extends BaseCrudService<IFacility, number> {
  //#region 'Variables'
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'facility');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getUserFacilitiesByFacilityId(facilityId: number): Observable<IFacility[]> {
    return this._http.get<IFacility[]>(`${this._apiEndpoint}/users/getByFacility/${facilityId}`);
  }

  public getBUIDByFacilityCode(facilityCode: string): Observable<Number> {
    return this._http.get<Number>(`${this._apiEndpoint}/users/GetBuId/${facilityCode}`);
  }
  //#endregion 'Get'

  //#region 'Create'
  //#endregion 'Create'

  //#region 'Update'
  //#endregion 'Update'

  //#region 'Delete'
  //#endregion 'Delete'
}
