import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-complete',
  templateUrl: './not-complete.component.html'
})
export class NotCompleteComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'
}
