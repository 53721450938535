import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlobModel } from '@models/BlobModel.model';
import { Observable } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { BaseCrudService } from './BaseCrud.service';
@Injectable({
  providedIn: 'root'
})
export class BlobService extends BaseCrudService<BlobModel, number> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'Blob');
  }

  public fileType: string;

  public sendFile(file: File, fileType: string): Observable<any> {
    const formData = new FormData();
    let fileExtension = file.name.split('.')[1];
    let newFileName = fileType + '.' + fileExtension;
    let param = new HttpParams().set('filename', newFileName);

    formData.append('fileSent', file);
    return this._http.post(`${this._apiEndpoint}/files`, formData, {
      params: param, // string parameter ('filename')
      reportProgress: true,
      observe: 'events',
      responseType: 'text' // takes care of the 200 response
    });
  }

  public getFile(fileName: string): any {
    this.fileType = fileName.split('.')[1];
    return this._http.get(`${this._apiEndpoint}/files/${fileName}`, {
      responseType: 'blob'
    });
  }

  public getFileList(): any {
    return this._http.get(`${this._apiEndpoint}/files`);
  }

  //the following logic will get the file name from getFileList() and
  //pass it to the getFile() method to get the file
  //else it will return an error message
  public processFileFromList(fileName: string): any {
    return this.getFileList().pipe(
      flatMap((result: string[]) => {
        for (let i = 0; i < result.length; i++) {
          if (result[i].includes(fileName)) {
            return this.getFile(result[i]);
          }
        }

        throw Error('File not found');
      }),

      tap((result) => {})
    );
  }
}
//#endregion 'Angular Life Cycle'

//#region 'Get'
//#endregion 'Get'

//#region 'Create'
//#endregion 'Create'

//#region 'Update'
//#endregion 'Update'

//#region 'Delete'
//#endregion 'Delete'

//#region 'Other'
//#endregion 'Other'
