import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

//#region 'MenuItem'
const SYSTEM_ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: 'System Administrators',
    routerLink: '/system-admin'
  },
  {
    label: 'Business Units',
    routerLink: '/business-units'
  }
];

const BU_ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    label: 'Users',
    routerLink: '/business-unit-user'
  }
];
//#endregion 'MenuItem'

//#region 'Component'
@Component({
  selector: 'app-navbar',
  template: ` <p-tabMenu [model]="model" styleClass="navbar"></p-tabMenu> `,
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  //#region 'Variables'
  public model: MenuItem[] = [];
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _route: Router) {
    _route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.loadModel();
      }
    });
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'

  private loadModel(): void {
    this.model = [];
    if (this._route.url === '/home') {
      this.model.push({ label: 'Welcome', routerLink: '/home' });
    } else {
      this.model.push({
        label: 'Analysis Reports',
        routerLink: '/analysis-reports'
      });
    }
  }
}
//#endregion 'Component'
