import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInspection } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

interface IFailureProbabilityResponse {
  PartialInspection: number[];
  FullInspection: number[];
  IdentifyPlugs: number[];
  NPlugs: number;
}

@Injectable({
  providedIn: 'root'
})
export class InspectionApiService extends BaseCrudService<IInspection, number> {
  //#region 'Variables'
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'inspection');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getInspectionDataAnalysisStatus(Id: any): Observable<any> {
    return this._http.get<any>(`${this._apiEndpoint}/getInspectionDataAnalysisStatus`, {
      params: { Id }
    });
  }

  public userCanDeleteInspection(inspectionId: number): Observable<any> {
    return this._http.get<any>(`${this._apiEndpoint}/userCanDeleteInspection/${inspectionId}`);
  }
  //#endregion 'Get'

  //#region 'Post'
  public insertInspection(inspection: Partial<IInspection>): Observable<any> {
    return this._http.post<any>(`${this._apiEndpoint}/insertInspection`, inspection);
  }
  //#endregion 'Post'

  //#region 'Puts'
  public inspection(inspection: Partial<IInspection>): Observable<any> {
    return this._http.put<any>(`${this._apiEndpoint}/inspection`, inspection);
  }

  public updateInspectionStatus(id: number, statusid: number): Observable<any> {
    const body = { InspectionId: id, StatusId: statusid };
    return this._http.put<any>(`${this._apiEndpoint}/UpdateInspectionStatus/${id}/${statusid}`, body);
  }

  public softDeleteInspection(inspectionId: number): Observable<any> {
    const body = { InspectionId: inspectionId };
    return this._http.put<any>(`${this._apiEndpoint}/SoftDeleteInspection/${inspectionId}`, body);
  }
  //#endregion 'Puts'

  //#region 'Delete'
  public hardDeleteInspection(inspectionId: number): Observable<any> {
    return this._http.delete<any>(`${this._apiEndpoint}/HardDeleteInspection/${inspectionId}`);
  }
  //#endregion 'Delete'

  //#region 'Files'
  public import(excelFile: File): Observable<any> {
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    return this._http.post<any>(`${this._apiEndpoint}/import`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public process(inspection: Partial<IInspection>): Observable<any> {
    return this._http.post<any>(`${this._apiEndpoint}/process`, inspection, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  public save(inspectionId: number, filesSent: File[]): Observable<any> {
    const formData = new FormData();
    filesSent.forEach((file) => {
      formData.append('filesSent', file, file.name);
    });

    const params = new HttpParams().set('InspectionId', `${inspectionId}`);
    return this._http.post(`${this._apiEndpoint}/save`, formData, { params });
  }

  public getfiles(inspectionId: number): Observable<any> {
    const params = new HttpParams().set('InspectionId', `${inspectionId}`);

    return this._http.get(`${this._apiEndpoint}/GetFiles`, {
      params,
      responseType: 'blob'
    });
  }
  //#endregion 'Files'

  recreateReport(inspectionId: number): Observable<any> {
    return this._http.post<any>(`${this._apiEndpoint}/recreate/${inspectionId}`, {});
  }
}
