import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { DisclaimerNoticeComponent } from './disclaimer-notice/disclaimer-notice.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
  declarations: [DisclaimerNoticeComponent, HeaderComponent, FooterComponent, NavbarComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CommonModule,
    MenuModule,
    TabMenuModule,
    ConfirmDialogModule
  ],
  exports: [DisclaimerNoticeComponent, HeaderComponent, FooterComponent, NavbarComponent],
  providers: []
})
export class ComponentsModule {}
