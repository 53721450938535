import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  //#region 'Variables'
  public appName: string = environment.appName;
  public version: string = environment.version;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'
}
