import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Equipment, Facility, Plant } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentApiService extends BaseCrudService<Equipment, number> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'equipment');
  }
  //#endregion 'Angular Life Cycle'
  //#region 'Get'

  public SyncEquipment(): Observable<Equipment[]> {
    return this._http.get<Equipment[]>(`${this._apiEndpoint}/EquipmentSync`);
  }

  public syncFacilities(): Observable<any> {
    return this._http.get<any>(`${this._apiEndpoint}/JDEFacilitiesSync`);
  }

  public getFacilities(): Observable<Facility[]> {
    return this._http.get<Facility[]>(`${this._apiEndpoint}/Facilities`);
  }

  public GetSpecificEquipent(equipmentId: string, facilityCode: string, plantId: string): Observable<Equipment> {
    const params = new HttpParams()
      .set('equipmentId', `${equipmentId}`)
      .set('facilityCode', `${facilityCode}`)
      .set('plantId', `${plantId}`);
    return this._http.get<Equipment>(`${this._apiEndpoint}/GetSpecificEquipment`, {
      params
    });
  }

  // public getPlants(fId: string): Observable<Plant[]> {
  //   return this._http.get<Plant[]>(`${this._apiEndpoint}/plants/${fId}`);
  // }

  // public getEquipments(fId: string, pId: string): Observable<Equipment[]> {
  //   return this._http.get<Equipment[]>(`${this._apiEndpoint}/${fId}/${pId}`);
  // }

  //#endregion 'Get'
}
