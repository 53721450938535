import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {
  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'
}
