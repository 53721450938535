import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInspectionReport } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionReportsApiService extends BaseCrudService<IInspectionReport, number> {
  //#region 'Variables'
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'inspection-reports');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getAllByBusinessUnit(buId: number): Observable<IInspectionReport[]> {
    return this._http.get<IInspectionReport[]>(`${this._apiEndpoint}/${buId}`);
  }

  public getDeletedInspectionReports(): Observable<IInspectionReport[]> {
    return this._http.get<IInspectionReport[]>(`${this._apiEndpoint}/DeletedInspection`);
  }

  public getByStatuses(): Observable<any[]> {
    return this._http.get<any[]>(`${this._apiEndpoint}/GetByStatuses`);
  }
  //#endregion 'Get'

  //#region 'Create'
  //#endregion 'Create'

  //#region 'Other'
  //#endregion 'Other'
}
