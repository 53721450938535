import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';

//#region 'Global Variables'
const APP_NAME = environment.appName;
//#endregion 'Global Variables'

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  //#region 'Variables'
  private titleSubject: BehaviorSubject<string> = new BehaviorSubject(APP_NAME);
  get title(): string {
    return this.titleSubject.value;
  }

  public title$: Observable<string> = this.titleSubject.asObservable();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(private _srvTitle: Title) {}
  //#endregion 'Angular Life Cycle'

  //#region 'General Methods'
  setTitle(value: string): void {
    const isTitleValid = !!value;

    if (isTitleValid) {
      this._srvTitle.setTitle(`${value} - ${APP_NAME}`);
      this.titleSubject.next(value);
    } else {
      this._srvTitle.setTitle(APP_NAME);
      this.titleSubject.next('');
    }
  }
  //#endregion 'General Methods'
}
