import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ILoggedUser } from '@models/index';
import { Role } from '@pages/auth/auth.constant';
import { UserApiService } from '@serv/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //#region 'Variables'
  private readonly userSubject: BehaviorSubject<ILoggedUser>;
  readonly currentUser$: Observable<ILoggedUser>;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(router: Router, private msalService: MsalService, private _apiUser: UserApiService) {
    this.userSubject = new BehaviorSubject(null);
    this.currentUser$ = this.userSubject.asObservable().pipe(shareReplay(1));
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Loads'
  public loadUserInfo(): Observable<ILoggedUser> {
    return this._apiUser.getUserCurrent().pipe(
      tap((data) => {
        this.userSubject.next(data);
      })
    );
  }
  //#endregion 'Loads'

  //#region 'Login'
  public login(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts?.length === 0) {
      this.msalService.instance.loginRedirect();
    }
  }

  public logout(): void {
    this.msalService.instance.logout();
    this.userSubject.next(null);
  }
  //#endregion 'Login'

  //#region 'Gets'
  get currentUser(): ILoggedUser | null {
    return this.userSubject.value as ILoggedUser;
  }

  get fullName(): string {
    return this.currentUser && `${this.currentUser.FirstName} ${this.currentUser.LastName}`;
  }

  get isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  get isAuthorized(): boolean {
    return !!this.currentUser && (this.currentUser.IsGuest || this.currentUser.ID > 0);
  }
  //#endregion 'Gets'

  //#region 'Validations'
  public hasRole(role: Role): boolean {
    if (this.isAuthorized && this.currentUser) {
      const { IsSystemAdmin, IsBUAdmin, IsOrgAdmin, IsGuest, IsFacilityAdmin } = this.currentUser;

      switch (role) {
        case Role.SystemAdmin:
          return IsSystemAdmin;
        case Role.BuAdmin:
          return IsBUAdmin;
        case Role.OrgAdmin:
          return IsOrgAdmin;
        case Role.Guest:
          return IsGuest;
        case Role.FacilityAdmin:
          return IsFacilityAdmin;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
  //#endregion 'Validations'
}
