import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInspectionComments, INewComment } from '@models/index';
import { Observable } from 'rxjs';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionCommentsApiService extends BaseCrudService<IInspectionComments, number> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'comments');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getInspectionComments(inspectionId: number): Observable<IInspectionComments[]> {
    return this._http.get<IInspectionComments[]>(`${this._apiEndpoint}/${inspectionId}`);
  }
  //#endregion

  //#region 'Post'
  public create(comment: INewComment): Observable<any> {
    return this._http.post<any>(`${this._apiEndpoint}`, comment);
  }
  //#endregion
}
