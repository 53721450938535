import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalGuard,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalService,
  MsalBroadcastService
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { ComponentsModule } from '@components/components.module';
import { GlobalHttpInterceptor } from '@interceptors/global-http.interceptor';
import { RetryInterceptor } from '@interceptors/retry.interceptor';
import { ErrorModule } from '@pages/error/error.module';
import { MainModule } from '@pages/home/main/main.module';
import { AuthService } from '@serv/shared/auth.service';
import { GlobalErrorHandlerService } from '@serv/shared/global-error-handler.service';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { finalize } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { MSALInstanceFactory } from '@pages/auth/auth.constant';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiEndpoint, [environment.scope]);
  protectedResourceMap.set(environment.functionAppEndPoint, [environment.scope]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid', environment.scope]
    },
    loginFailedRoute: '/login-failed'
  };
}

export function appInitializerFactory(authService: AuthService, msalService: MsalService) {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      await msalService.instance.initialize().then(async () => {
        await msalService.instance.handleRedirectPromise().then(() => {
          if (authService.isAuthenticated) {
            authService
              .loadUserInfo()
              .pipe(finalize(() => resolve()))
              .subscribe();
          } else {
            authService.login();
          }
        });
      });
    });
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    ProgressSpinnerModule,
    HttpClientModule,

    MainModule,
    ErrorModule,
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    ToastModule,
    ComponentsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AuthService, MsalService],
      useFactory: appInitializerFactory,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    DialogService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
