import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AzureFunctionLog } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class AzureFunctionLoggingService {
  // tslint:disable-next-line: typedef
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
  };

  protected readonly _apiEndpoint: string;
  constructor(protected _http: HttpClient) {
    this._apiEndpoint = `${environment.apiEndpoint}/azurefunctionlogging`;
  }

  // GET ALL
  public getAll(): Observable<AzureFunctionLog[]> {
    return this._http.get<AzureFunctionLog[]>(`${this._apiEndpoint}`, this.httpOptions);
  }

  // GET inspectionId
  public getByInspectionId(inspectionId: number): Observable<AzureFunctionLog> {
    return this._http.get<AzureFunctionLog>(`${this._apiEndpoint}/${inspectionId}`, this.httpOptions);
  }

  // GET error/inspectionId
  public getErrorByInspectionId(inspectionId: number): Observable<string> {
    return this._http.get<string>(`${this._apiEndpoint}/error/${inspectionId}`, this.httpOptions);
  }
}
