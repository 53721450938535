import { NgModule, Optional, SkipSelf } from '@angular/core';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [NotFoundComponent, NotAuthorizedComponent, GenericErrorComponent],
  imports: []
})
export class ErrorModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ErrorModule
  ) {
    if (parentModule) {
      throw new Error('Error Module is already loaded. Import only in AppModule');
    }
  }
}
