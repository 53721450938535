import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBusinessUnit, IBusinessUnitUser, IFacility, IFacilityUser, ILoggedUser } from '@models/index';
import { LoadingIndicatorService } from '@serv/shared/loading-indicator.service';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { BaseCrudService } from './BaseCrud.service';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends BaseCrudService<IBusinessUnitUser, number> {
  //#region 'Variables'
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient, protected _srvLoader: LoadingIndicatorService) {
    super(_http, 'users');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public getUserCurrent(): Observable<ILoggedUser> {
    return this._http.get<ILoggedUser>(`${this._apiEndpoint}/current`);
  }

  public GetUserByEmail(email: string): Observable<ILoggedUser> {
    const params = new HttpParams().set('email', `${email}`);
    return this._http.get<ILoggedUser>(`${this._apiEndpoint}/GetUserByEmail`, {
      params
    });
  }

  public getUsersByBU(buId: number): Observable<IBusinessUnitUser[]> {
    return this._http.get<IBusinessUnitUser[]>(`${this._apiEndpoint}/getByBU/${buId}`);
  }
  public getUsersByFacility(facilityId: number): Observable<IFacilityUser[]> {
    return this._http.get<IFacilityUser[]>(`${this._apiEndpoint}/getByFacility/${facilityId}`);
  }

  public getParticipants(query: string): Observable<ILoggedUser[]> {
    return this._http.get<ILoggedUser[]>(`${this._apiEndpoint}/getParticipants/${query}`);
  }

  public getCurrentUserAdminBUs(): Observable<IBusinessUnit[]> {
    this._srvLoader.show();
    return this._http.get<IBusinessUnit[]>(`${this._apiEndpoint}/currentUserAdminBUs`).pipe(
      catchError(super.handleError<IBusinessUnit[]>('getCurrentUserAdminBUs', [])),
      finalize(() => this._srvLoader.hide())
    );
  }

  public getCurrentUserBUs(): Observable<IBusinessUnit[]> {
    return this._http.get<IBusinessUnit[]>(`${this._apiEndpoint}/currentUserBUs`);
  }
  public getCurrentUserFacilities(): Observable<IFacility[]> {
    return this._http.get<IFacility[]>(`${this._apiEndpoint}/currentUserFacilities`);
  }
  //#endregion 'Get'

  //#region 'Create'
  public createUser(dto: ILoggedUser): Observable<ILoggedUser> {
    return this._http.post<ILoggedUser>(`${this._apiEndpoint}/create`, dto);
  }
  public createFacilityUser(dto: IFacilityUser): Observable<IFacilityUser> {
    return this._http.post<IFacilityUser>(`${this._apiEndpoint}/facility`, dto);
  }
  //#endregion 'Create'

  //#region 'Delete'
  public deleteById(id: number): any {
    return this._http.delete(`${this._apiEndpoint}/${id}`, {
      responseType: 'text'
    });
  }
  public deleteFacilityUser(facilityId: number, userId: number): any {
    return this._http.delete(`${this._apiEndpoint}/facility/${facilityId}/${userId}`, {
      responseType: 'text'
    });
  }
  //#endregion 'Delete'

  //#region 'update'
  public updateFacilityUser(id: number, dto: IFacilityUser): any {
    return this._http.put(`${this._apiEndpoint}/facility/${id}`, dto);
  }
  //#endregion 'update'

  //#region 'Other'
  public acceptDisclaimer(id: number): Observable<ILoggedUser> {
    return this._http.put<ILoggedUser>(`${this._apiEndpoint}/${id}/accept-disclaimer`, null);
  }
  //#endregion 'Other'
}
