import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  //#region 'Variables'
  public title$: string = 'Heat Exchanger';
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor() {
    //intentional
  }

  ngOnInit(): void {
    //intentional
  }
  //#endregion 'Angular Life Cycle'
}
