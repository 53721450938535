<!-- Header -->
<div class="row" *ngIf="currentUser$ | async as currentUser">
  <!-- Menu -->
  <div class="col-3">
    <div data-html2canvas-ignore="true" class="div-center-100">
      <button
        *ngIf="showMenuButton"
        class="p-button-rounded p-button-text p-button-bold p-button-s130"
        type="button"
        pButton
        pRipple
        icon="pi pi-bars"
        aria-label="Menu"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu class="menu" styleClass="menu" #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
  </div>
  <!-- Menu -->

  <!-- Title -->
  <div class="col-6 text-center p-col">
    <img alt="Chevron" src="assets/images/hallmark_trans.png" />
    <a class="brand" routerLink="/" title="Chevron" style="text-decoration: none">
      <span style="display: block">{{ brand }}</span>
    </a>
    <span data-html2canvas-ignore="true" style="display: block; color: #0b2d71">
      {{ disclaimer }}
    </span>
  </div>
  <!-- Title -->

  <!-- Actions -->
  <div class="col-3">
    <div data-html2canvas-ignore="true" class="div-center-100 flex-end">
      <!-- Service Now -->
      <a
        class="btn-icon-link fl"
        title="Service Now"
        target="_blank"
        rel="noopener noreferrer"
        href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981&sysparm_u_appid=5043320"
      >
        <button
          pButton
          class="p-button-text p-button-rounded p-button-lg p-button-bold"
          icon="pi pi-question-circle"
        ></button>
      </a>
      <!-- Service Now -->

      <!-- Help & Download -->
      <a
        class="btn-icon-link fl"
        download="HEAT User Guide"
        title="User Guide"
        target="_blank"
        rel="noopener noreferrer"
        (click)="downloadFile('userManual')"
      >
        <button
          pButton
          class="p-button-text p-button-rounded p-button-lg p-button-bold"
          icon="pi pi-book"
          style="font-weight: 900 !important"
        ></button>
      </a>
      <!-- Help -->

      <div class="vertical-divider fl"></div>

      <!-- User -->
      <div class="text-sub">{{ currentUser.FullName }}</div>
      <!-- User -->
    </div>
  </div>
  <!-- Actions -->
</div>
<!-- Header -->

<!-- #region 'Notification' -->
<p-confirmDialog icon="pi pi-check"></p-confirmDialog>
<!-- #endregion -->
