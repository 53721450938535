<!-- Disclaimer -->
<p class="dialog__content">
  {{ msg }}
</p>
<!-- Disclaimer -->
<!-- Accept -->
<div class="dialog__footer">
  <button pButton type="button" label="I Agree" (click)="onAcceptDisclaimer()"></button>
</div>
<!-- Accept -->
