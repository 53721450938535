import { BrowserCacheLocation, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@environment';

//#region 'Functions'
export function loggerCallback(logLevel: LogLevel, message: string): void {
  switch (logLevel) {
    case LogLevel.Error:
      // console.error(`[MSAL Error]: `, message);
      break;
    case LogLevel.Warning:
      // console.warn(`[MSAL Warning]: `, message);
      break;
    case LogLevel.Info:
      // console.log(`[MSAL Info]: `, message);
      break;
    case LogLevel.Verbose:
      // console.log(`[MSAL Verbose]: `, message);
      break;
  }
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.auth.clientId,
      authority: environment.auth.authority,
      postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
      redirectUri: environment.auth.redirectUri
    },
    system: {
      loadFrameTimeout: 30000,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    }
  });
}

//#endregion 'Functions'

//#region 'Enums'
export enum Role {
  BuAdmin = 0,
  SystemAdmin = 1,
  OrgAdmin = 2,
  Guest = 3,
  FacilityAdmin = 4
}
//#endregion 'Enums'
